import { Grid, Paper, SegmentedControl, Select, Group, Text, TextInput, Button, Table } from "@mantine/core";
import { useState } from "react";

export default function TowerList({items, leftClickView}){
    console.log(items)
    const rows = items.map((tower)=>{
        var twr_id = tower[0]
        var twr_name = tower[3];
        var mnt_height = tower[4];
        return(
        <tr>
            <td>{twr_name}</td>
            <td>{mnt_height}</td>
            <td><Button onClick={(e) => {leftClickView(tower)}}>View</Button></td>
        </tr>
        )
    })
    return(
        <Table mx={15}>
            <thead>
                <tr>
                <th>Name</th>
                <th>Mount Height (feet)</th>
                <th>Action</th>
                </tr>
            </thead>
        <tbody>{rows}</tbody>
        </Table>
    )
}