import axios from 'axios';
import Cookies from 'js-cookie';

export const upsertTower = async (towerName, towerHeight, towerLat, towerLng, towerNotes, towerId) => {
    let token = Cookies.get('auth_token')
    try {
    const response = await axios.post('/api/locations/tower/upsert', {
        token,
        towerName,
        towerHeight,
        towerLat,
        towerLng,
        towerNotes,
        towerId
    });
    return response.data
    } catch (error) {
    console.error('Error:', error);
    return 'Error';
    }
};