import axios from 'axios';
import Cookies from 'js-cookie';

export const updateApDetails = async (apCoverageId, apAzimuth, apAzimuthWidth, apTechnology, updatePaths, apId) => {
    let token = Cookies.get('auth_token')
    try {
    const response = await axios.post('/api/devices/roles/aps/update', {
        token,
        apCoverageId, 
        apAzimuth, 
        apAzimuthWidth, 
        apTechnology, 
        updatePaths, 
        apId
    });
    return response.data
    } catch (error) {
    console.error('Error:', error);
    return 'Error';
    }
};