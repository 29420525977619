import { Grid, Paper, SegmentedControl, Select, Group, Text, TextInput, Table } from "@mantine/core";
import { useState } from "react";

export default function ScheduleList({items}){
    console.log(items)
    const rows = items.map((item)=>{
        return(
        <tr>
            <td>{item.start_time}</td>
            <td>{item.summary}</td>
            <td>{item.type}</td>
            <td>{item.installer_group}</td>
            <td>{item.location}</td>
        </tr>
        )
    })
    return(
        <Table mx={15}>
            <thead>
                <tr>
                <th>Start Time</th>
                <th>Summary</th>
                <th>Type</th>
                <th>Technician</th>
                <th>Location</th>
                </tr>
            </thead>
        <tbody>{rows}</tbody>
        </Table>
    )
}