//external imports
import React, { useEffect, useState } from "react";
import { Burger, Button, Flex, Grid, Group, Loader, SegmentedControl, Switch, Text, Title } from "@mantine/core";


//internal imports
import { logoutUser } from "../hooks/logoutUser";
import { getUserActiveOrg } from "../hooks/users/useGetUserActiveOrg";
import { changeActiveOrg } from "../hooks/users/useChangeActiveOrg";


function handleLogout() {
  logoutUser();
  window.location.reload();
}

function HeaderContent({navbarVisible, setNavbarVisible}) {
// -------------------------------------
  var appVersion = "3.4.0"
// -------------------------------------

  const [activeOrg, setActiveOrg] = useState()
  const [userGroup, setUserGroup] = useState()
  const [orgSwitchDisabled, setOrgSwitchDisabled] = useState(false)


  const breakPointMd = 12
  const breakPointLg = 4

  useEffect(() => {
    getUserActiveOrg().then((response) => {
      setActiveOrg(response.org)
      setUserGroup(response.user_group)
    })
  }, [])

    function orgToSwitch(org){
      if(org == 2){
        return true
      }else{
        return false
      }
    }
  function switchOrg(){
    setOrgSwitchDisabled(true)
    let newOrg = 0
    if(activeOrg == 1){
      newOrg = 2
    }else{
      newOrg = 1
    }
    changeActiveOrg(newOrg).then((response) => {
      if(response.status == "success"){
        setActiveOrg(newOrg)
        setOrgSwitchDisabled(false)
        window.location.reload();
      }
    })
  }

  return (
    <Grid  align="center" >

      <Grid.Col span={4}>
        <Group noWrap={true} position="left">
          <Burger size={"md"}  opened={navbarVisible} onClick={() => setNavbarVisible(!navbarVisible)} />
          <Title> ISPCloud</Title>
          <Text pt={15} >{appVersion}</Text>
        </Group>
      </Grid.Col>

      <Grid.Col  span={4}>
        {orgSwitchDisabled && <Loader size="md" color="orange" /> }
        {userGroup == 1 && <Switch onLabel="TREPIC" offLabel="AZN" size="xl" color="orange" checked={orgToSwitch(activeOrg)}  onChange={()=>{switchOrg()}} disabled={orgSwitchDisabled}/>}
      </Grid.Col>


      <Grid.Col align="center" span={4}>
        <Group  position="right" px={30}>
          <Button onClick={() => handleLogout()} color="red"> Logout </Button>
        </Group>
      </Grid.Col>

    </Grid>

  );
}
export default HeaderContent;