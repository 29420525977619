import { SimpleGrid, Text, Stack, Header, Title, Table, Button, Group, Modal, TextInput, MultiSelect, Space, Select } from "@mantine/core"
import React, { useEffect, useState } from 'react';

import {getInstallersAndAvailability} from '../hooks/scheduling/useGetInstallersAndAvailability'
import {upsertInstallerGroup} from '../hooks/scheduling/useUpsertInstallerGroup'
import {deleteInstallerGroup} from '../hooks/scheduling/useDeleteInstallerGroup'
import { addInstallerAvailability } from "../hooks/scheduling/useAddInstallerAvailability";
import { editInstallerAvailability } from "../hooks/scheduling/useEditInstallerAvailability";
import { deleteInstallerAvailability } from "../hooks/scheduling/useDeleteInstallerAvailability";


export default function Schedule(){

    const [updateData, setUpdateData] = useState(false)

    const [installers, setInstallers] = useState()
    const [availability, setAvailability] = useState()
    const [groups, setGroups] = useState()
    const [allInstallers, setAllInstallers] = useState()

    const [viewAddEditGroupModal, setViewAddEditGroupModal] = useState(false)  
    const [editGroup, setEditGroup] = useState()
    const [editGroupName, setEditGroupName] = useState()
    const [editGroupMembers, setEditGroupMembers] = useState()

    const [viewAddEditAvailabilityModal, setViewAddEditAvailabilityModal] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState();
    const [selectedGroupAvailability, setSelectedGroupAvailability] = useState();
    
    const [installerAvailabilityID, setInstallerAvailabilityID] = useState();
    const [availabilityGroup, setAvailabilityGroup] = useState();
    const [availabilityWeekday, setAvailabilityWeekday] = useState();
    const [availabilityHour, setAvailabilityHour] = useState();
    const [availabilityMinute, setAvailabilityMinute] = useState();
    const [availabilityType, setAvailabilityType] = useState();

    var hours = []
    for (var i = 1; i <= 24; i++){
        hours.push({value: i, label: i.toString()})
    }
    var minutes = []
    for (var i = 0; i < 60; i++){
        minutes.push({value: i, label: i.toString()})
    }
    useEffect(() => {
        getInstallersAndAvailability().then((res)=>{ 
            setInstallers(res.data.users)
            setGroups(res.data.groups)
            setAvailability(res.data.availability)
            console.log(res.data.availability);
        })
    }, [updateData])

    function handleEditGroup(groupId){
        let selectedGroup = groups.filter(group => group[0] === groupId)
        let members = installers.filter(installer => installer[1] === selectedGroup[0][0])
        let memberIds = members.map((member)=>{return member[2]})
        setEditGroupMembers(memberIds)
        setEditGroupName(selectedGroup[0][1])
        setEditGroup(selectedGroup[0])
        setViewAddEditGroupModal(true)
    }
    
    function handleCloseEditModal(){
        setEditGroup()
        setEditGroupMembers()
        setEditGroupName()
        setViewAddEditGroupModal(false)
    }

    function handleDeleteGroup(){
        let confirmed = window.confirm("Are you sure you want to delete this group?")
        if(confirmed){
            deleteInstallerGroup(editGroup[0]).then((res)=>{
                if(res.status === "success"){
                    console.log("Group Deleted");
                    setUpdateData(!updateData);
                    handleCloseEditModal();
                }
            })
        }
    }

    function handleSaveGroup(){
        if(!editGroupName){
            alert("Please enter a group name");
            return
        }
        if(editGroup){
            upsertInstallerGroup(editGroupName, editGroupMembers, editGroup[0]).then((res)=>{
                if(res.status === "success"){
                    console.log("Group Updated");
                    handleCloseEditModal();
                    setUpdateData(!updateData);
                }
            })
        }else{
            upsertInstallerGroup(editGroupName, editGroupMembers, null).then((res)=>{
                if(res.status === "success"){
                    console.log("Group Created");
                    handleCloseEditModal();
                    setUpdateData(!updateData);
                }
            })
        }
    }

    function handleSelectGroup(groupID){
        setSelectedGroup(groupID);
        let selectedGroupAvailability = availability.filter(timeslot => timeslot[1] === groupID);
        setSelectedGroupAvailability(selectedGroupAvailability);
    }

    function numberToDay(number){
        var day;
        switch(number){
            case 0:
                day = "Monday";
                break;
            case 1:
                day = "Tuesday";
                break;
            case 2:
                day="Wednesday";
                break;
            case 3:
                day = "Thursday";
                break;
            case 4:
                day = "Friday";
                break;
            case 5:
                day = "Saturday";
                break;
            case 6:
                day = "Sunday";
                break;
            default:
                day = "N/A";

    }
    return day
}

function numberToApptType(number){
    var day;
    switch(number){
        case 1:
            day = "Install";
            break;
        case 2:
            day = "Repair";
            break;
        case 3:
            day="Removal";
            break;
        default:
            day = "N/A";
}
return day
}

function handleEditAvailability(id){
    setInstallerAvailabilityID(id)
    var av = availability.find((item) => item[0] === id)
    setAvailabilityGroup(av[1])
    setAvailabilityWeekday(av[2])
    setAvailabilityHour(av[3])
    setAvailabilityMinute(av[4])
    setAvailabilityType(av[6])
    setViewAddEditAvailabilityModal(true)

}

function handleCloseEditAvailaibilityModal(){
    setInstallerAvailabilityID()
    setAvailabilityGroup()
    setAvailabilityWeekday()
    setAvailabilityHour()
    setAvailabilityMinute()
    setAvailabilityType()
    setViewAddEditAvailabilityModal(false)
}

function handleSaveAvailability(){
    if(installerAvailabilityID){
        editInstallerAvailability(availabilityGroup, availabilityWeekday, availabilityHour, availabilityMinute, availabilityType, installerAvailabilityID)
        .then((res)=>{
            if(res.status === "success"){
                console.log("Availability Updated");
                handleCloseEditAvailaibilityModal();
                setUpdateData(!updateData);
            }
        })
    }else{
        addInstallerAvailability(availabilityGroup, availabilityWeekday, availabilityHour, availabilityMinute, availabilityType)
        .then((res)=>{
            if(res.status === "success"){
                console.log("Added Availability");
                handleCloseEditAvailaibilityModal();
                setUpdateData(!updateData);
            }
        })
    }
    
}

function handleDeleteAvailability(id){
    let confirmed = window.confirm("Are you sure you want to delete this item?");
    if(confirmed){
        deleteInstallerAvailability(id)
        .then((res)=>{
            if(res.status === "success"){
                console.log("Deleted Successfully");
                setUpdateData(!updateData);
            }
        })
    }
}
    return(
        <>
            <SimpleGrid cols={2} spacing="lg" breakpoints={[{ maxWidth: '75rem', cols: 1, spacing: 'sm' },]}>
                <Stack>
                    <Group >
                            <Title>Installer Group</Title>
                            <Button onClick={()=>{setViewAddEditGroupModal(true)}} color="green">Add New</Button>
                    </Group>
                    <Table>
                        <thead>
                            <tr>
                                <th>Group Name</th>
                                <th>Members</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {groups && groups.map((group)=>{
                                let members = installers.filter(installer => installer[1] === group[0])
                                return(
                                    <tr key={group[0]}>
                                        <td>{group[1]}</td>
                                        <td>{members.map((member) =>{return member[0]+" "})}</td>
                                        <td><Button onClick={() =>{handleEditGroup(group[0])}}>Edit</Button><Button onClick={()=>handleSelectGroup(group[0])} color="green">Select</Button></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Stack>
                <Stack>
                    <Group>
                        <Title>Availability</Title>
                        <Button color="green" onClick={()=>setViewAddEditAvailabilityModal(true)}>Add New</Button>
                        </Group>
                    
                    <Table>
                        <thead>
                            <tr>
                                <th>Day of Week</th>
                                <th>Hour</th>
                                <th>Minute</th>
                                <th>Timezone</th>
                                <th>Appointment Type</th>
                                <th>Action</th>

                            </tr>
                        </thead>
                        <tbody>
                        {selectedGroupAvailability && selectedGroupAvailability.map((timeslot)=>{ 
                            return(
                                <tr key={timeslot[0]}>
                                    <td>{numberToDay(timeslot[2])}</td>
                                    <td>{timeslot[3]}</td>
                                    <td>{timeslot[4]}</td>
                                    <td>{timeslot[5]}</td>
                                    <td>{numberToApptType(timeslot[6])}</td>
                                    <td>
                                        <Button color="yellow" onClick={()=>{handleEditAvailability(timeslot[0])}}>Edit</Button>
                                        <Button color="red" onClick={()=>{handleDeleteAvailability(timeslot[0])}}>Delete</Button>
                                        </td>
                                    
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </Stack>
            </SimpleGrid>



            <Modal size={"xl"} opened={viewAddEditGroupModal} onClose={()=>{handleCloseEditModal()}} title="Installer Group" >
                <Group grow>
                {editGroup && <Text>Group ID: {editGroup[0]}</Text>}
                {editGroup && <Button onClick={()=>{handleDeleteGroup()}} color="red">Delete</Button>}
                <Space h={"xl"}></Space>
                </Group>
                 <TextInput label="Group Name"  value={editGroupName} onChange={(e)=>{setEditGroupName(e.target.value)}}/>
                 <Space h={"xl"}></Space>
                 {installers && 
                    <MultiSelect 
                        dropdownPosition="bottom" 
                        value={editGroupMembers} 
                        data={installers.map((installer)=>{return {value: installer[2], label: installer[0] }})} 
                        label="Members"  
                        onChange={setEditGroupMembers}/> }
                 <Space h={"xl"}></Space>
                 <Space h={"xl"}></Space>
                 <Group grow>
                    <Button onClick={()=>{handleSaveGroup()}} color="yellow">Save</Button>
                 </Group>
            </Modal>



            <Modal size={'xl'} opened={viewAddEditAvailabilityModal} onClose={()=>handleCloseEditAvailaibilityModal()} title="Appt Slot">
                    {groups && <Select 
                        label="Installer Group"
                        placeholder="Choose One"
                        data={
                            groups.map((group)=>{return {value: group[0], label: group[1]}})
                        }
                        value={availabilityGroup}
                        onChange={setAvailabilityGroup}
                    />
}
                    <Select
                        value={availabilityWeekday}
                        onChange={setAvailabilityWeekday}
                        label="Weekday"
                        placeholder="Any Day"
                        data={[
                            {value: 0, label: "Monday"},
                            {value: 1, label: "Tuesday"},
                            {value: 2, label: "Wednesday"},
                            {value: 3, label: "Thursday"},
                            {value: 4, label: "Friday"},
                            {value: 5, label: "Saturday"},
                            {value: 6, label: "Sunday"}
                        ]}
                        
                         />
                    <Select 
                        value={availabilityHour}
                        onChange={setAvailabilityHour}
                        label = "Hour"
                        placeholder = "Pick An Hour"
                        data={hours}
                    />
                    <Select 
                        value={availabilityMinute}
                        onChange={setAvailabilityMinute}
                        label = "Minute"
                        placeholder = "Pick a minute"
                        data = {minutes}
                    />

                    <Select 
                        value={availabilityType}
                        onChange={setAvailabilityType}
                        label = "Appointment Type"
                        placeholder = "Choose An Appointment Type"
                        data = {[
                            {value: 1, label: "Install"},
                            {value: 2, label: "Repair"},
                            {value: 3, label: "Removal"}
                        ]}
                    />
                    <Space h={"xl"}></Space>
                    <Group grow>
                    <Button color="green" onClick={()=>{handleSaveAvailability()}}>Submit</Button>
                    </Group>
            </Modal>
        </>
    )
}