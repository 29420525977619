import axios from 'axios';
import Cookies from 'js-cookie';

export const upsertInstallerGroup = async (groupName, groupMembers, groupId) => {
    let token = Cookies.get('auth_token')
    try {
    const response = await axios.post('/api/scheduling/groups/upsert', {
        token,
        groupName,
        groupMembers,
        groupId
    });
    return response.data
    } catch (error) {
    console.error('Error:', error);
    return 'Error';
    }
};