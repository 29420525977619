import { Alert, Avatar, Button, Card, Center, Container, Input, PasswordInput, Space, Stack, Text, Title } from '@mantine/core';
import { createContext, useContext, useState, useEffect } from 'react';

import {loginUser} from '../hooks/loginUser';

function Login(){
    //Controlled Variables
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [authError, setAuthError] = useState(null);

    function handleLogin(email, password){
        loginUser(email, password)
        .then((error) => {
            if (error) {
                console.log(error+"error")
                setAuthError(error);
            }else{
                window.location.reload()
            }
        });
    }
    return (

        <Container size="400px">
                <Space h="xl" />

                <Card shadow='lg' padding="lg" radius="lg" withBorder>
                    <Stack>
                        <Center>
                            <Avatar color="orange" radius="xl" size="xl"/>
                        </Center>
                        <Center>
                            <Title order={1}>Login</Title>
                        </Center>
                        <Input value={email} onChange={(e) =>setEmail(e.target.value)} placeholder='Email'></Input>
                        <PasswordInput value={password} onChange={(e) =>setPassword(e.target.value)} placeholder='Password'></PasswordInput>
                        <Button color="orange" onClick={() =>handleLogin(email, password)}>Submit</Button>
                        {authError && <Alert color="red">{authError}</Alert>}
                    </Stack>
                </Card>


            </Container>
    )
}

export default Login