import React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';

const mapContainerStyle = {
  width: '100%',
  height: '400px',

};



function MapWithMarker({ lat, lng }) {
  const center = {
    lat: lat,
    lng: lng,
  };



  if(lat && lng) {return (
  
    <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={16} mapTypeId={window.google.maps.MapTypeId.HYBRID}>

         {center && <Marker position={center } onLoad={(marker) =>{console.log(center);}} />}
      </GoogleMap>

  );}
}

export default MapWithMarker;