import { Paper, Text, Avatar, Grid, Anchor, Title, Group, TextInput, NumberInput, Select, Button, Space, Switch } from "@mantine/core";
import { useEffect, useState } from "react";


import { getApDetails } from "../../hooks/devices/useGetApDetails";
import { updateApDetails } from "../../hooks/devices/useUpdateApDetails";

export default function ApDetail({apId, apName, apIp, closeModal}){
    const [reloadData, setReloadData] = useState(false);
    const [saveApDisabled, setSaveApDisabled] = useState(false);

    const [apPaths, setApPaths] = useState()

    const [apCoverageId, setApCoverageId] = useState(0)
    const [apAzimuth, setApAzimuth] =useState()
    const [apAzimuthWidth, setApAzimuthWidth] =useState()
    const [apTechnology, setApTechnology] = useState()
    const [updatePaths, setUpdatePaths] = useState(true)

    useEffect(()=>{
        getApDetails(apId)
        .then((res)=>{
            if(res.status === "success"){
                if(res.data.ap_paths.length > 0){
                    setApPaths(res.data.ap_paths)
                }
                console.log(res.data)
                if(res.data.ap_info.coverage_id){
                    setApCoverageId(res.data.ap_info.coverage_id)
                }
                setApAzimuth(res.data.ap_info.azimuth)
                setApAzimuthWidth(res.data.ap_info.coverage_width)
                setApTechnology(res.data.ap_info.technology)
            }else{
                console.log("Data request failure.");
            }
        })
    }, [reloadData])

    function handleSaveApInformation(){
        setSaveApDisabled(true)
        updateApDetails(apCoverageId, apAzimuth, apAzimuthWidth, apTechnology, updatePaths, apId)
        .then((res)=>{
            console.log(res);
            setSaveApDisabled(false)
            closeModal()
        })
    }

    return(
        <>
            <Title order={2}>Access Point</Title>
            <Group>
                <Title order={5}>Name: </Title>
                <Text>{apName}</Text>
            </Group>
            <Group>
                <Title order={5}>IP Address: </Title>
                <Text>{apIp}</Text>
            </Group>
            
            <NumberInput value={apAzimuth} onChange={setApAzimuth} placeholder="0->360" label={"Ap Azimuth Direction"} />
            <NumberInput value={apAzimuthWidth} onChange={setApAzimuthWidth} placeholder="0->360" label={"Ap Azimuth Width"} />
            <Select value={apTechnology} onChange={setApTechnology} data={[
                {value: 1, label: "UBNT Wave"},
                {value: 2, label: "UBNT LTU"},
                {value: 3, label: "UBNT AC"},
                {value: 4, label: "Cambium 3Ghz Medusa"}
            ]} label={"Ap Technology"} />
            <Space defaultChecked h="lg"/>
            <Switch checked={updatePaths} onChange={(e)=> {setUpdatePaths(e.currentTarget.checked)}} label="Update AP Path" />
            <Space h="xl" />
            <Group grow>
                <Button onClick={()=>{handleSaveApInformation()}} disabled={saveApDisabled} color="green" >Save AP Information</Button>
            </Group>
        </>
    )
}