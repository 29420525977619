import { Paper, Text, Avatar, Grid, Anchor } from "@mantine/core";
import { useEffect, useState } from "react";

export default function RoleCard({roleName, roleIp, roleType, onClick, roleId}){



    return(
        <>
            <Paper onClick={()=>{onClick(roleId, roleName, roleIp, roleType)}} withBorder shadow="md" p="md" >
                <Grid align="center">
                    <Grid.Col span={4}>
                        <Avatar size={"lg"} />
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <Text>{roleName}</Text>
                        <Anchor href={"https://"+roleIp} target={"_blank"}>{roleIp}</Anchor>
                        
                    </Grid.Col>
                </Grid>
            </Paper>
            
        </>
    )
}
