import { TextInput, Text, Stack, Group, Alert } from "@mantine/core";

export default function CustomerInfo({
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phoneNumber,
    setPhoneNumber,
    emailAddress,
    setEmailAddress,
    addressOne,
    setAddressOne,
    addressTwo,
    setAddressTwo,
    city,
    setCity,
    state,
    setState,
    zip,
    setZip,
    mapMarker,
    setMapMarker,
    formErrors
}){
    return(
        <Stack>
            <Text>Customer Contact Info</Text>
            <Group grow>
                <TextInput error={formErrors.firstName} value={firstName} onChange={(e)=>{setFirstName(e.target.value)}} label="First Name" placeholder="John" />
                <TextInput error={formErrors.lastName} value={lastName} onChange={(e)=>{setLastName(e.target.value)}} label="Last Name" placeholder="Doe" />
            </Group>
            <TextInput error={formErrors.phoneNumber} value={phoneNumber} onChange={(e)=>{setPhoneNumber(e.target.value)}} label="Phone Number" placeholder="623-349-4172" />
            <TextInput error={formErrors.emailAddress} value={emailAddress} onChange={(e)=>{setEmailAddress(e.target.value)}} label="Email Address" placeholder="email@trepicnetworks.com" />
            <TextInput error={formErrors.addressOne} value={addressOne} onChange={(e)=>{setAddressOne(e.target.value)}} label="Address 1" placeholder="3950 E Riggs Rd." />
            <TextInput value={addressTwo} onChange={(e)=>{setAddressTwo(e.target.value)}} label="Address 2" placeholder="Suite 100" />
            <Group grow>
                <TextInput error={formErrors.city} value={city} onChange={(e)=>{setCity(e.target.value)}} label="City" placeholder="Chandler" />
                <TextInput error={formErrors.state} value={state} onChange={(e)=>{setState(e.target.value)}} label="State" placeholder="AZ" />
                <TextInput error={formErrors.zip} value={zip} onChange={(e)=>{setZip(e.target.value)}}label="Zip" placeholder="85249" />
            </Group>
            {formErrors.mapMarker && <Alert color="dark">Please Select a Location On The Map</Alert>}

            {mapMarker && 
            <Stack>
                <Text>Coordinates of Map Location</Text>
                <Text>{mapMarker.lat.toString() + ", "+ mapMarker.lng.toString()}</Text>
            </Stack>}


            

        </Stack>
    )
}