import axios from 'axios';
import Cookies from 'js-cookie';

export const loginUser = async (email, password) => {
  try {
    const response = await axios.post('/api/users/authenticate', {
      email,
      password
    });

    const user_data = response.data;

    if (user_data && user_data.token) {
      // Set the cookie with the user data, expiring in 7 days
      Cookies.set('user_data', JSON.stringify(user_data), { expires: 7 });
      Cookies.set('auth_token', user_data.token)
      console.log('User logged in successfully');
      return null;
    } else {
      console.log('Invalid email or password');
      return 'Invalid email or password';
    }
  } catch (error) {
    console.error('Error logging in:', error);
    return 'Error logging in';
  }
};