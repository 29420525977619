import axios from 'axios';
import Cookies from 'js-cookie';

export const editInstallerAvailability = async (installer_group, weekday, hour, minute, appt_type, row_id) => {
    let token = Cookies.get('auth_token')
    try {
    const response = await axios.post('/api/scheduling/availability/edit', {
        token,
        installer_group, 
        weekday, 
        hour, 
        minute, 
        appt_type,
        row_id
    });
    return response.data
    } catch (error) {
    console.error('Error:', error);
    return 'Error';
    }
};