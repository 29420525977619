// AuthContext.js
import { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { logoutUser } from '../hooks/logoutUser';


const AuthContext = createContext();

async function verifyToken(token){
  if(token){
    const response = await axios.post('/api/users/verify', {
      token
    });
    if(response.data.status != "success"){
      logoutUser()
      window.location.reload()
    }
  }
  
  
}

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  
  useEffect(() => {
    const authToken = Cookies.get('auth_token');
    const verified = verifyToken(authToken)
    setIsAuthenticated(authToken !== undefined);
    setToken(authToken);
  }, []);

  const value = {
    isAuthenticated,
    setIsAuthenticated,
    token,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
