import { Grid, Paper, SegmentedControl, Select, Group, Text, TextInput, Table, Modal } from "@mantine/core";
import { useEffect, useState } from "react";

import GenericList from "../components/general/GenericList";
import ScheduleList from "../components/scheduling/ScheduleList";
import TowerList from "../components/towers/TowerList";
import TowerDetail from "../components/towers/TowerDetail"

import {getServiceAppts} from "../hooks/scheduling/useGetServiceAppts";
import {getTowers} from "../hooks/locations/useGetTowers";

export default function List(){
    const [itemsToList, setItemsToList] = useState("scheduling")
    const [generalSearchTerm, setGeneralSearchTerm] = useState("")
    const [numberOfRows, setNumberOfRows] = useState(25);
    const [orderBy, setOrderBy] = useState("");

    const breakPointMd = 12
    const breakPointLg = 4


    const [scheduleItems, setScheduleItems] = useState([])
    const [towerItems, setTowerItems] = useState([])

    const [towerDetailID, setTowerDetailID] = useState();
    const [towerDetailModalOpened, setTowerDetailModalOpened] = useState();

    useEffect(() =>{
        if(itemsToList == 'scheduling'){
            getServiceAppts(numberOfRows, orderBy, generalSearchTerm)
            .then((res)=>{
                setScheduleItems(res.data)
            });
        }
    }, [generalSearchTerm, numberOfRows, itemsToList])

    useEffect(() => {
        if(itemsToList =='towers'){
            getTowers()
            .then((res)=>{
                setTowerItems(res.towers)
            });
        }
    })

    function leftClickView(tower){
        setTowerDetailID(tower[0]);
        setTowerDetailModalOpened(true);
    }

    function handleCloseTowerDetail(){
        setTowerDetailModalOpened(false); 
    }
    
    return(
        <>
            <Paper withBorder radius={0} >

                <Grid  py={15} >

                    <Grid.Col pl={20} md={breakPointMd}  lg={breakPointLg}  align="center" >
                        <Group position="center">

                        <Text>Results:</Text>
                        <Select value={numberOfRows} onChange={setNumberOfRows} data={[
                            {label: "25", value: 25},
                            {label: "50", value: 50},
                            {label: "100", value: 100},
                            {label: "200", value: 200},
                            {label: "500", value: 500}
                        ]} />
                        </Group>
                        
                    </Grid.Col>

                    <Grid.Col align="center" bg="white" md={breakPointMd}  lg={breakPointLg}>
                        <SegmentedControl
                            value={itemsToList}
                            onChange={setItemsToList} 
                            data={[
                                {label: "Towers", value: "towers"},
                                {label: "Devices", value: "devices", disabled: true},
                                {label: "Customers", value: "customers", disabled: true},
                                {label: "Scheduling", value: "scheduling"}
                            ]}
                        />
                    </Grid.Col>

                    <Grid.Col px={20} align="center"  bg="white" md={breakPointMd}  lg={breakPointLg}>
                        <TextInput value={generalSearchTerm} onChange={(e)=>{setGeneralSearchTerm(e.target.value)}}  placeholder="Search" />
                    </Grid.Col>

                </Grid>

            </Paper>

            <Modal fullScreen size={"xl"} opened={towerDetailModalOpened} onClose={()=>{handleCloseTowerDetail()}}>
                <TowerDetail tower_id={towerDetailID} />
            </Modal>

            {itemsToList === "scheduling" && <ScheduleList items={scheduleItems}/>}
            {itemsToList === "towers" && <TowerList items={towerItems} leftClickView={leftClickView}/>}

        </>
    )
}